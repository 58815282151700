import { RouteComponentProps } from '@reach/router';
import React, { FC } from 'react';

import { PublicRoute } from '../components/PublicRoute';

const CaseStudies: FC<RouteComponentProps> = (props) => {
  return <PublicRoute {...props}>Case Studies Page</PublicRoute>;
};

export default CaseStudies;
